import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../components/home.vue'
import aboutUs from '../components/about/aboutUs.vue'
import contact from '../components/contact.vue'
import newsList from '../components/news/newsList.vue'
import products from '../components/products.vue'
import newsInfo from '../components/news/newsInfo.vue';
import productsInfo from '../components/products/productsInfo.vue';
import support from '../components/support/support.vue'
import solution from '../components/solution/solution.vue'
import joinUs from '../components/joinUs.vue'
Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  { path: '/', redirect: '/index' },
  {
    path: '/index',
    name: 'index',
    component: index,
    children: [

    ],
    meta: {}
  },
  { path: '/news', name: 'news', component: newsList },
  { path: '/about', name: 'about', component: aboutUs },
  { path: '/contact', name: 'contact', component: contact },
  { path: '/products', name: 'products', component: products },
  { path: '/newsInfo', name: 'newsInfo', component: newsInfo },
  { path: '/productsInfo', name: 'productsInfo', component: productsInfo },
  { path: '/support', name: 'support', component: support },
  { path: '/solution', name: 'solution', component: solution },
  { path: '/joinUs', name: 'join', component: joinUs }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// router.afterEach((to, from, next) => {
//   window.scrollTo(0, 0)
//   next()
// })
router.afterEach((to, from) => {
  window.scrollTo(0, 0)
})
export default router
