import service from "@/service";
import Router from "vue-router"
// 获取新闻
export function getNews(params) {
    return service({
        method: 'get',
        url: '/news/pub/getList',
        params
    })
}

// 获取产品
export function getProducts() {
    return service({
        method: 'get',
        url: '/products/pub/getList',
    })
}

// 获取合作伙伴
export function getPartner() {
    return service({
        method: 'get',
        url: '/partner/pub/getList',
    })
}

// 获取新闻分类
export function getNewsCate() {
    return service({
        method: 'get',
        url: '/newsCate/pub/getList'
    })
}

// 新闻提交点击量
export function addClicks(data) {
    return service({
        method: 'post',
        url: '/news/pub/updateClicksById',
        data
    })
}

// 联系人提交信息
export function addContact(data) {
    return service({
        method: 'post',
        url: '/contact/pub/addContact',
        data
    })
}

//获取服务支持信息
export function getSupportList(data) {
    return service({
        method: 'get',
        url: '/support/pub/getList',
        data
    })
}


// 获取主要业务以及案例
export function getBusinessList() {
    return service({
        method: 'get',
        url: '/business/pub/getList',
    })
}

// 获取页脚信息
export function getFooterInfo() {
    return service({
        method: 'get',
        url: '/footer/pub/getList'
    })
}

//获取解决方案
export function getSolutionList() {
    return service({
        method: 'get',
        url: '/solution/pub/getList',
    })
}
export function getBannerList() {
    return service({
        method: 'get',
        url: '/banner/pub/getList',
    })
}

// 获取部门信息
export function getDepartment() {
    return service({
        method: 'get',
        url: '/department/pub/getList'
    })
}

// 获取服务优势
export function getMerits() {
    return service({
        method: 'get',
        url: '/merits/pub/getList'
    })
}

// 获取发展历程
export function getDevHistory() {
    return service({
        method: 'get',
        url: '/devHistory/pub/getList'
    })
}

// 获取公司简介信息
export function getCompanyInfo() {
    return service({
        method: 'get',
        url: '/company/pub/getList'
    })
}

// 获取招聘信息
export function getRecruitInfo() {
    return service({
        method: 'get',
        url: '/recruit/pub/getList'
    })
}
//查询缓存信息
export function getStorage(data) {
    return service({
        method: 'post',
        url: '/storageManage/pub/getList',
        data
    })
}
//新增缓存信息
export function addStorage(data) {
    console.error(data);
    return service({
        method: 'post',
        url: '/storageManage/pub/add',
        data
    })
}
//编辑缓存信息
export function updateStorage(data) {
    return service({
        method: 'post',
        url: '/storageManage/pub/update',
        data
    })
}
//销毁缓存信息
export function deleteStorage(data) {
    return service({
        method: 'post',
        url: '/storageManage/pub/delete',
        data
    })
}


// 提交用户访问信息
export function addVisitorInfo(data) {
    return service({
        method: 'post',
        url: '/visitor/pub/add',
        data
    })
}

// 获取地图密钥
export function getMapKey() {
    return service({
        method: 'get',
        url: '/mapKey/pub/getList',
    })
}

// 根据id获取新闻内容
export function getNewsInfo(params) {
    let id = params.id
    return service({
        method: 'get',
        url: '/news/pub/getListById',
        params: {
            id: id
        }
    })
}