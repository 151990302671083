<template>
  <div>
    <section class="a3K">
      <div class="aa"
        style="margin-bottom: 30px;">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="av dark:a1k dark:a1Z a13 a8 a1K a9 a1x ai a1l sm:a3L md:aq[40px] md:a3M[50px] xl:a3B[50px] 2xl:aq[60px] 2xl:a3M[70px] wow fadeInUp"
              data-wow-delay=".1s">
              <div v-for="(item, index) in partnerList"
                :key="index">
                <a :href="item.offical_url"
                  target="_blank"
                  rel="nofollow noreferrer"
                  class="a8 a9 a1x lg:aB[130px] xl:aB[150px] 2xl:aB[160px] a3N sm:a3O xl:a3P 2xl:aO aq[15px] a3Q hover:a3R aJ hover:a17 dark:a3S dark:hover:a17 a1p">
                  <img :src="item.logo_img[0]"
                    alt="uideck" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getPartner } from '../api/api'
import { setData, getData } from '@/utils/setFile.js'
export default {
  data() {
    return {
      partnerList: [],
    };
  },
  // mounted(){
  //     that = this
  // },
  created() {
    // let otherArr = JSON.parse(getData('news_img'))
    // otherArr.forEach((v, i) => {
    //   this.newsList.push({ news_img: otherArr[i] })
    // });
    this.getPartnerList()
  },
  methods: {
    getPartnerList() {
      getPartner().then(res => {
        this.partnerList = res.data.rows
      })
    },
  }
}
</script>