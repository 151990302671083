export const getOffsetTime = (date = new Date(), obj) => {
    let nowDate = new Date();
    let year = obj.year || obj.y;
    let month = obj.month || obj.m;
    let day = obj.day || obj.d;
    let hours = obj.hours || obj.hh;
    let minutes = obj.minutes || obj.mm;
    let seconds = obj.seconds || obj.ss;
    let { mode = "after" } = obj;
    if (mode == "before") {
        year *= -1;
        month *= -1;
        day *= -1;
        hours *= -1;
        minutes *= -1;
        seconds *= -1;
    }
    if (year) {
        nowDate = nowDate.setFullYear(nowDate.getFullYear() + year);
        nowDate = new Date(nowDate);
    }
    if (month) {
        nowDate = nowDate.setMonth(nowDate.getMonth() + month);
        nowDate = new Date(nowDate);
    }
    if (day) {
        nowDate = nowDate.setDate(nowDate.getDate() + day);
        nowDate = new Date(nowDate);
    }
    if (hours) {
        nowDate = nowDate.setHours(nowDate.getHours() + hours);
        nowDate = new Date(nowDate);
    }
    if (minutes) {
        nowDate = nowDate.setMinutes(nowDate.getMinutes() + minutes);
        nowDate = new Date(nowDate);
    }
    if (seconds) {
        nowDate = nowDate.setSeconds(nowDate.getSeconds() + seconds);
        nowDate = new Date(nowDate);
    }
    return nowDate.getTime();
}

export const getDeltTime = (number)=> {
    return new Date().valueOf() + number * 3600000
}