import axios from 'axios'
// import { getToken } from '@/utils/setToken.js'
import { Message } from 'element-ui'
const service = axios.create({
    // baseURL: '/api', // baseURL会自动加在请求地址上
    baseURL: process.env.NODE_ENV === 'development' ? '/api' : 'https://fc-mp-cdae0fc1-a699-4eb9-bfa9-518a563176c3.next.bspapp.com/http/router/common',

    timeout: 3000
})

service.interceptors.response.use(response => {
    switch (response.data.code) {
        case 0:
            return response
        case 400:
            clearLocalStorage()
            Message({
                message: '请求不合法！',
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 401:
            clearLocalStorage()
            Message({
                message: response.data.msg,
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 404:
            clearLocalStorage()
            Message({
                message: response.data.msg,
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            return response
        case 405:
            clearLocalStorage()
            Message({
                message: "http请求方式有误",
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 500:
            clearLocalStorage()
            Message({
                message: "服务器出了点小差，请稍后再试",
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 501:
            clearLocalStorage()
            Message({
                message: "服务器不支持当前请求所需要的某个功能",
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
    }
}, error => {
    switch (error.code) {
        case "ECONNABORTED":
            clearLocalStorage()
            Message({
                message: error.response.data,
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 405:
            Message({
                message: 'http请求方式有误',
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 500:
            Message({
                message: '服务器出了点小差，请稍后再试',
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
        case 501:
            Message({
                message: '服务器不支持当前请求所需要的某个功能',
                type: 'error',
                duration: 1500,
                customClass: 'element-error-message-zindex'
            })
            break
    }
    return Promise.reject(error)
})
/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
service.adornParams = (params = {}, openDefultParams = true) => {
    var defaults = {
        't': new Date().getTime()
    }
    return openDefultParams ? merge(defaults, params) : params
}

export function clearLocalStorage() {
    localStorage.clear();
    // Vue.cookie.delete('Authorization')
    // store.commit('resetStore')
    // router.options.isAddDynamicMenuRoutes = false
}

export default service