<template>
  <div>
    <section id="news"
      class="ad a1H a3A a3g[3%]">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L aB[570px] a1M wow fadeInUp"
              data-wow-delay=".1s"
              style="margin-bottom: 50px">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                新闻资讯
              </h2>
              <!-- <p class="a1S aH md:a1T a1U md:a1U">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p> -->
            </div>
          </div>
        </div>
        <div class="a8 a1K ab[-16px]">
          <div class="a7 md:a1_/2 lg:a1_/3 ae a1r news_"
            v-for="(item, index) in newsList"
            :key="index"
            @click="goToNews(item)">
            <div class=" wow fadeInUp"
              style="margin-bottom:30px"
              data-wow-delay=".15s">
              <div class="as[70px] at[70px] a8 a9 a1x a13 a1k a29 a1V a1W imgShow">
                <img :src="newsList[index].news_img"
                  alt="" />
              </div>
              <h3 class="a1g a1A dark:aI a27 sm:a2u lg:a27 xl:a2u a2v">
                {{ item.news_title }}
              </h3>
              <div class="textDispaly_box">
                <div class="a1S aH a1U a1R a3H[10px] textDispaly"
                  v-html="item.news_text">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="more a1r"
          @click="goPage">更多</div>
      </div>
      <!-- <div class="nR"><img src="static/picture/CR.png"
          alt=""></div>
      <div class="nL"><img src="static/picture/CL.png"
          alt=""></div> -->
    </section>
  </div>
</template>
<script>
import { getNews } from '../../api/api'
import { setData, getData } from '@/utils/setFile.js'
export default {
  data() {
    return {
      newsList: [],
    };
  },
  created() {
    // let otherArr = JSON.parse(getData('news_img'))
    // if (otherArr instanceof Array) {
    //   otherArr.forEach((v, i) => {
    //     this.newsList.push({ news_img: otherArr[i] })
    //   });
    // }
    this.getNewsList()
  },

  methods: {
    getNewsList() {
      getNews().then(res => {
        if (res.data.code == 0) {
          this.newsList = res.data.rows.slice(0, 3)
          // let newsImgArr = []
          // this.newsList.forEach((v, i) => {
          //   newsImgArr.push(this.newsList[i].news_img)
          // });
          // setData('news_img', JSON.stringify(newsImgArr))
        }
      })
    },
    goToNews(val) {
      console.log(val._id)
      const { href } = this.$router.resolve({
        path: '/newsInfo',
        query: { id: val._id }
      })
      window.open(href, '_blank')
    },
    goPage() {
      this.$router.push({ name: 'news' })
    },

  }
}
</script>
<style>
.title {
  font-size: 24px;
}
.more {
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  color: #7f7f7f;
}
.news_ {
  padding: 20px;
  transition: all ease 0.2s;
  border-radius: 10px;
}
.news_:hover {
  transform: translateY(-2px);
  background-color: rgba(246, 248, 255, 0.8);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
}
</style>