import { getOffsetTime, getDeltTime } from './timeUtil'
import { getStorage, addStorage, updateStorage, deleteStorage } from '../api/api'
//设置缓存图片
export const setData = async (file, data, time = 2) => {
    //获取到缓存时间，设置缓存时效时间 --- 时间戳
    // let expire_time =  getOffsetTime(new Date(), {
    //     hours:time,
    //     mode:"after", // after 之后 before 之前
    // });
    let expire_time = getDeltTime(time)
    let obj = {
        data: data,
        expire_time: expire_time
    }
    try {
        let res = await getStorage({ key: file })
        if (res.data.code == 0 && res.data.total && res.data.total != 0) {
            // res.data.rows.forEach((v, i) => {
            // });
            let backKey = res.data.rows[0]
            if (backKey.key !== file || backKey.value !== data || backKey.expire_at !== expire_time) {
                backKey.key = file
                backKey.value = data
                backKey.expired_at = expire_time
                await updateStorage({ ...backKey, id: backKey._id })
            }
        } else {
            await addStorage({ key: file, value: data, expired_at: expire_time })
        }
        obj = JSON.stringify(obj)
        return localStorage.setItem(file, obj)
    } catch {
        err => {
        }
    }
}
//获取缓存
export const getData = async (file) => {
    const storData = localStorage.getItem(file)
    if (!storData) {
        return null
    }
    const parsedData = JSON.parse(storData)
    const currentTimestamp = new Date().getTime()
    if (currentTimestamp < parsedData.expire_time) {
        return parsedData.data;
    } else {
        await deleteStorage({key:file})
        localStorage.removeItem(file);
        return null
    }
}

// 防抖
export const Debounce = (fn, t) => {
    let delay = t || 500
    let timer
    return function () {
        let args = arguments;
        if (timer) {
            clearTimeout(timer)
        }
        let callNow = !timer
        timer = setTimeout(() => {
            timer = null
        }, delay)

        if (callNow) fn.apply(this, args)
    }
}