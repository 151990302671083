<template>
  <div>
    <section id="features"
      class="a1k a3g[3%] a1I[50px] a1J[50px]">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L aB[570px] a1M a3s[100px] wow fadeInUp"
              data-wow-delay=".1s">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                业务方向
              </h2>
              <!-- <p class="a1S aH md:a1T a1U md:a1U">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p> -->
            </div>
          </div>
        </div>
        <div class="a8 a1K ab[-16px]">
          <div class="a7 md:a1_/2 ae"
            v-for="(item, index) in directionList"
            :key="index">
            <div class="a3s[70px] wow fadeInUp mf_"
              data-wow-delay=".15s">
              <div class="icon_box a8 a9 a1x a13 a1k a29 a1V a1W">
                <img :src="item.img"
                  alt=""
                  style="min-height:40px;width:40px">
              </div>
              <h3 class="a1g a1A dark:aI a27 sm:a2u lg:a27 xl:a2u a2v">
                {{ item.name }}
              </h3>
              <p class="a1S aH a1U a1R a3H[10px] mf_w">
                {{ item.intro }}
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getSupportList } from '../api/api'
export default {
  data() {
    return {
      directionList: [
        // { name: '移动端手机网站与APP', img: 'static/picture/mf-icon01.png', intro: '专注移动端手机网站设计、微网站、手机APP定制开发，创造有活力的品牌网站，在移动互联网市场中取得先机。' },
        // { name: '微信小程序定制开发', img: 'static/picture/mf-icon02.png', intro: '优秀的小程序开发团队，为您量身定制您专属小程序，为您拉近用户距离，永远第一时间与微信用户建立连接！' },
        // { name: 'U3D游戏定制与开发', img: 'static/picture/mf-icon03.png', intro: '团队凭借多年的游戏行业经验为企业量身定制，游戏软硬件，VR,3D交互等，让您的企业在残酷的网络竞争中脱颖而出，立于不败之地。' },
        // { name: '系统集成', img: 'static/picture/mf-icon04.png', intro: '积累大量系统集成项目经验，为您提供完整系统集成方案和施工，为您提升效率，快速优秀的完成项目' }
      ]
    }
  },
  created() {
    this.getSupportData()
  },
  methods: {
    getSupportData() {
      getSupportList().then(res => {
        if (res.data.code == 0 && res.data.total && res.data.total != 0) {
          res.data.rows.forEach((v, i) => {
            this.directionList.push({ name: v.title, img: v.home_img_url, intro: v.content })
          });
        }
      })
    },
  }
}
</script>
<style>
.icon_box {
  width: 70px;
  height: 70px;
}

.mf_ {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>