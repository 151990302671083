<template>
  <div>
    <header class=" a2 a3 a4 a5 a6 a7 a8 a9"
      :class="{sticky:isMove,header:isMove==false}">
      <div class="aa">
        <div class="a8 ab[-16px] a9 ac ad">
          <div class="ae af ag">
            <a href="index"
              class="a7 ah ai header-logo"
              v-if="logo">
              <img :src="logo"
                alt="logo" />
            </a>
          </div>
          <div class="a8 ae ac a9 a7">
            <div>
              <button id="navbarToggler"
                @click="isShow"
                aria-label="Mobile Menu"
                class="ah a3 ak al/2 am[-50%] lg:aj focus:an ao ap aq[6px] ar"
                :class="{navbarTogglerActive:show==false}">
                <span class="ad as[30px] at[2px] au[6px] ah av dark:aw"></span>
                <span class="ad as[30px] at[2px] au[6px] ah av dark:aw"></span>
                <span class="ad as[30px] at[2px] au[6px] ah av dark:aw"></span>
              </button>
              <nav id="navbarCollapse"
                class="a3 ax lg:ay lg:ae xl:az aw dark:av lg:dark:a2 lg:a2 aA ar aB[250px] a7 lg:ag lg:a7 ak aC lg:ah lg:aD lg:aE"
                :class="{aj:show}">
                <!-- style="margin-top:10px" -->
                <ul class="aF lg:a8">
                  <li class="ad aG"
                    v-for="(item,index) in menuList"
                    :key="index+'label'"
                    @click="goToPage(index)"
                    :class="{menuList:checked==item.comp}">
                    <a class="menu-scroll aH text-dark dark:aI group-hover:aJ aK lg:aL lg:aM lg:aN a8 aO lg:aP a1r ">
                      {{item.name}}
                    </a>
                  </li>

                  <li class="ad aG submenu-item"
                    v-for="(item,index) in menusList"
                    :key="index"
                    @click="goToPages(index)"
                    :class="{menuList:checked==item.comp}">

                    <a href="javascript:void(0)"
                      class="aH text-dark dark:aI group-hover:aJ aK lg:aL lg:aM lg:aS lg:aT a8 aO lg:aP lg:aQ xl:aR ad ">
                      {{ item.name }}
                      <span v-show="show"
                        class="after:a3 after:aU after:aV after:aW after:aX after:aY after:aZ lg:after:a_ after:a10 after:al/2 after:am[-50%] after:a11[-2px]"></span>
                    </a>
                    <div class="submenu aj ad lg:a3 as[250px] aC lg:a12[110%] a5 a13 lg:aA a14 lg:ah lg:a15 lg:a16 group-hover:a17 lg:group-hover:a18 lg:group-hover:aC aw dark:av a19[top] a1a">
                      <a class="ah a1b a1c aq[10px] ae text-dark dark:aI hover:aJ"
                        v-for="(item,index) in item.menu"
                        :key="index"
                        :href="item.url"
                        target="_blank">
                        {{item.name}}
                      </a>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="a8 a1d a9 a1e lg:a1f">

            </div>
          </div>
        </div>
      </div>
    </header>
    <a href="javascript:void(0)"
      @click="backTop"
      class="a9 a1x a1k aI a2c a2d a13 a2e a2f a2g a2h a1Y[999] hover:a1n hover:a2i a1p a1a a2j back-to-top a2k"
      :style="{display:isFlag ? 'flex' :'none'}">
      <span class="a28 a2l a2m a2n a2o aZ a11[6px]"></span>
    </a>
  </div>
</template>
<script>
import { getProducts } from '../api/api'
import { getData } from '../utils/setFile'
import { getFooterInfo } from '../api/api'
export default {
  data() {
    return {
      menuList: [
        { name: '首页', comp: 'index' },
        { name: '公司介绍', comp: 'about' },
        // { name: '新闻资讯', comp: 'news' },
        { name: '服务支持', comp: 'support' },
        { name: '联系我们', comp: 'contact' },
        // { name: '解决方案', comp: 'solution' },
        { name: '加入我们', comp: 'join' },
      ],
      menusList: [
        // { name: '产品案例', menu: [], comp: 'productsInfo' },
        // { name: '服务支持', menu: [],comp: 'support' },
        // { name: '解决方案', menu: [] },
      ],
      isMove: false,
      isFlag: false,
      show: true,
      count: 0,
      logo: '',
      checked: 'index'
    }
  },
  created() {
    this.getProductsList()
    this.getLogo()
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop)
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
  },

  methods: {
    async init() {
      const localLogoData = await getData('logo')
      if (localLogoData !== null) {
        this.logo = localNewsData.logo
      } else {
        this.getLogo()
      }
      this.getProductsList()
    },
    // 返回顶部
    backTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    scrollToTop() {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 60) {
        that.isFlag = true
        that.isMove = true
      } else {
        `1`
        that.isFlag = false
        that.isMove = false
      }
    },
    getProductsList() {
      getProducts().then(res => {
        if (res.data.code == 0) {
          let products = []
          res.data.rows.forEach((v, i) => {
            products.push({ name: res.data.rows[i].products_name, url: res.data.rows[i].products_url })
          });
          this.menusList[0].menu = products
        }
      })
    },
    goToPage(val) {
      this.checked = this.menuList[val].comp
      this.$router.push({
        name: this.menuList[val].comp,
      })
      this.show = true
      this.count--
    },
    goToPages(val) {
      this.checked = this.menusList[val].comp
      this.$router.push({
        name: this.menusList[val].comp,
      })
      this.show = true
      this.count--
    },
    // 响应式点击按钮出现菜单
    isShow() {
      if (this.count == 0) {
        this.show = false
        this.count++
      } else {
        this.show = true
        this.count--
      }
    },
    getLogo() {
      getFooterInfo().then(res => {
        this.logo = res.data.rows[0].logo
      })
    },
  }
}
</script>
<style>
.aj {
  display: none;
}
.showPB {
  visibility: visible;
  display: block;
  top: 100%;
  opacity: 1;
}
.menuList {
  /* background-color: skyblue; */
  color: #4a6cf7;
}
</style>