<template>
  <div>
    <section id="features"
      class="a1k a3g[3%] a1I[120px] a1J[50px]">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L aB[570px] a1M a3s[100px] wow fadeInUp"
              data-wow-delay=".1s">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                发展历程
              </h2>
              <p class="a1S aH md:a1T a1U md:a1U">
                Development history
              </p>
            </div>
          </div>
        </div>
        <div class="a8 a1K ab[-16px]"
          style="justify-content: space-between">
          <div class="a7 md:a1_/2 devH ae dev_box"
            :class="index===historyList.length-1?'historyList-final ':''"
            v-for="(item,index) in historyList"
            :key="index">
            <div class="ad a1H aw dark:a3w[#1D2144] a1n a1l a42 a13 a1V wow fadeInUp dev_box_item"
              data-wow-delay=".1s">
              <div class="a8 ac a9"
                style="margin-top: 20px">
                <!-- style=" writing-mode: tb-rl;" -->
                <h3 class="a1g a1A dark:aI a1O a2X price">
                  <span>{{item.period}}</span>
                </h3>

              </div>
              <div>
                <div class="a8 a9 a30">
                  <p class="aH a1R a1S a45">{{item.affair}}</p>
                </div>
              </div>
            </div>
            <!-- <div>
              <img src="static/picture/jianTou.png"
                alt=""
                style=" width: 400px;margin-left: 20px">
            </div> -->
          </div>

        </div>
      </div>

    </section>

  </div>
</template>
<script>
import { getDevHistory } from '../../api/api'
export default {
  data() {
    return {
      historyList: []
    }
  },
  created() {
    getDevHistory().then(res => {
      this.historyList = res.data.rows
    })
  }
}
</script>
<style scoped>
.dev_box_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 300px;
  /* width: 420px; */
}
.dev_box_item span {
  font-size: 20px;
}
.historyList-final img {
  visibility: hidden;
}
</style>