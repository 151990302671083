<template>
  <div class="main-box main-box-pos">
    <section id="products"
      class="bg-primary a1Z a1I[120px] a3A main-box-pos"
      style="padding-top: 4rem; padding-bottom: 0;">
      <div class="aa bannerBox">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <!-- <div class="a1L aB[570px] a1M wow fadeInUp" data-wow-delay=".2s" > -->
            <!-- <h1 class=" a1A dark:aI a1g a1O sm:a1P md:a3D a2D sm:a2D md:a2D a2v">
                                Startup Focused Tailwind CSS Template
                            </h1>
                            <p class=" a1R a1T md:a27 a1U md:a1U a1S dark:aI dark:a3E a2s">
                                A Complete Tailwind CSS Web Template Crafted for - Startup,
                                SaaS, Business, Software and Agencies. Comes with high-quality
                                design and everything you need!
                            </p>
                            <div class="a8 a9 a1x">
                                <a href="#features" class=" aH a2P aI a1k a3q a1l hover:a2i a3F a13 a1p a1a a2j">
                                    Get Started
                                </a>
                                <a href="#about"
                                    class=" aH a2P a1A a1 a29 dark:aI dark:aw dark:a29 a3q a1l hover:a3G dark:hover:a3G a3F a13 a1p a1a a2j">
                                    Learn More
                                </a>
                            </div> -->
            <el-carousel height="34rem">
              <el-carousel-item v-for="item in bannerList"
                :key="item._id">
                <img :src="item.pc_img"
                  alt=""
                  class="carouselImg">
              </el-carousel-item>
            </el-carousel>

            <!-- </div> -->
          </div>
        </div>
      </div>

    </section>
  </div>
</template>
<script>
import { getBannerList } from '../../api/api'
import { setData, getData } from '@/utils/setFile.js'
export default {
  data() {
    return {
      bannerList: [],
    };
  },
  created() {
    // let otherArr = JSON.parse(getData('products_img'))
    // if (otherArr instanceof Array) {
    //     otherArr.forEach((v, i) => {
    //         this.productsList.push({ products_img: otherArr[i] })
    //     });
    // }
    this.getBannerData()
  },
  methods: {
    getBannerData() {
      getBannerList().then(res => {
        if (res.data.code == 0 && res.data.total && res.data.total != 0) {
          res.data.rows.forEach((v, i) => {
            this.bannerList.push(v)
          });
        }
      })
    },
  }
};
</script>
<style lang="scss">
.carouselImg {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.bannerBox {
  max-width: 100% !important;
  padding: 15px !important;
}
@media (max-width: 540px) {
  .el-carousel__container {
    height: 180px !important;
  }
}
</style>