<template>
  <div>

    <main>
      <banner></banner>
      <businessDirection></businessDirection>
      <!-- <products></products> -->
      <!-- <indexNewsVue></indexNewsVue> -->
      <certificate></certificate>
      <!-- <partners></partners> -->
    </main>

  </div>
</template>
<script>
import certificate from './certificate/certificate.vue';
import indexNewsVue from '../components/news/indexNews.vue';
import partners from './partners.vue'
import products from './products.vue';
import businessDirection from './businessDirection.vue'
import banner from './banner/banner.vue'
import axios from 'axios';
import { addVisitorInfo, getMapKey } from '../api/api'
export default {
  components: {
    indexNewsVue,
    partners,
    products,
    businessDirection,
    banner,
    certificate
  },
  data() {
    return {
      key: '',
      info: {
        ip: '',
        address: ''
      }
    };
  },
  created() {
    axios.get('https://api.ipify.org').then(res => {
      if (res.data) {
        this.info.ip = res.data
        this.getKey()
      }
    })

  },

  methods: {
    // async getIPCity() {
    //   await getMapKey().then(res => {
    //     var data = {
    //       key: res.data.info.mapKey
    //       // key: 'KSPBZ-V5L33-5EM3D-35N5X-DIY66-CRF7J', //密钥
    //       // key: '5FFBZ-ZWXCP-AIMDQ-LEFML-SO3Z6-2QBT2'
    //     }
    //     var url = 'https://apis.map.qq.com/ws/location/v1/ip' //腾讯地理位置信息接口
    //     data.output = 'jsonp' // 解决跨域问题
    //     this.$jsonp(url, data).then((res) => {
    //       // console.log('ip所属区', res.result.ad_info.district);
    //       this.info.address = `${res.result.ad_info.nation}${res.result.ad_info.province}${res.result.ad_info.city}${res.result.ad_info.district}`
    //       return res
    //     })

    //     console.log(this.info, '222')
    //     // addVisitorInfo(this.info)
    //   })

    // },
    getKey() {
      getMapKey().then(res => {
        this.key = res.data.info.mapKey
        this.getCity()
      })
    },
    async getCity() {
      var data = {
        key: this.key
      }
      var url = 'https://apis.map.qq.com/ws/location/v1/ip' //腾讯地理位置信息接口
      data.output = 'jsonp' // 解决跨域问题
      await this.$jsonp(url, data).then((res) => {
        // console.log('ip所属区', res.result.ad_info.district);
        this.info.address = `${res.result.ad_info.nation}${res.result.ad_info.province}${res.result.ad_info.city}${res.result.ad_info.district}`
        return res
      })
      addVisitorInfo(this.info)
    },

  }

};
</script>
<style scoped>
</style>
  