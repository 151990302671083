<template>
  <div>
    <section id="products"
      class="bg-primary a1Z a1I[120px] a3A">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L aB[570px] a1M a3s[100px] wow fadeInUp"
              data-wow-delay=".1s">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                我们能做什么
              </h2>
              <p class="a1S aH md:a1T a1U md:a1U">
                深度探讨客户需求与痛点，高效高质服务，实现双赢
              </p>
            </div>
          </div>
        </div>
        <el-skeleton style="width: 100%;"
          :loading="loading"
          animated>
          <template slot="template">
            <div style="display: flex;align-items: center;">
              <div style="padding-bottom: 14px; width: 50%;">
                <el-skeleton-item variant="h3"
                  style="width: 70%" />
                <div style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;">
                  <el-skeleton-item variant="text"
                    style="margin-right: 16px; width: 10%; " />
                  <el-skeleton-item variant="text"
                    style="width: 40%;" />
                </div>
                <el-skeleton-item variant="text"
                  style=" margin-top: 16px; width: 85%" />
              </div>
              <el-skeleton-item variant="image"
                style=" width: 628px; height: 419px;" />
            </div>
          </template>
          <template>
            <div class="a8 a1K ab[-16px] a1x">
              <div class="aa"
                v-for="(item, index) in supportList"
                :key="item._id">
                <div class="a8 a1K a9 ab[-16px]">
                  <div class="a7 lg:a1_/2 ae"
                    v-if="item.sortNo % 2 == 0">
                    <div class="a1M lg:a2I a2s lg:a2t wow fadeInUp"
                      data-wow-delay=".15s">
                      <img :src="item.img_url"
                        alt="about image"
                        class="ag a1L lg:a2J" />
                    </div>
                  </div>
                  <div class="a7 lg:a1_/2 ae">
                    <div class="aB[470px] wow fadeInUp"
                      data-wow-delay=".2s">
                      <div class="a1N">
                        <h3 class="a1g a1A dark:aI a27 sm:a2u lg:a27 xl:a2u a1Q">
                          {{ item.title }}
                        </h3>
                        <p class="a1S aH sm:a1T a1U sm:a1U a1R">
                          {{ item.content }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="a7 lg:a1_/2 ae"
                    v-if="item.sortNo % 2 == 1">
                    <div class="a1M lg:a2I a2s lg:a2t wow fadeInUp"
                      data-wow-delay=".15s">
                      <img :src="item.img_url"
                        alt="about image"
                        class="ag a1L lg:a2J" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>

      </div>
    </section>
  </div>
</template>
<script>
import { getSupportList } from '../../api/api'
import { setData, getData } from '@/utils/setFile.js'
export default {
  data() {
    return {
      loading: true,
      supportList: [],
    };
  },
  created() {
    // let otherArr = JSON.parse(getData('products_img'))
    // if (otherArr instanceof Array) {
    //     otherArr.forEach((v, i) => {
    //         this.productsList.push({ products_img: otherArr[i] })
    //     });
    // }
    // this.getSupportData()
    this.init()
  },
  methods: {
    async init() {
      const localData = await getData('supportData')
      if (localData !== null) {
        this.supportList = localData
        this.loading = false
      } else {
        this.getSupportData()
      }

    },
    getSupportData() {
      getSupportList().then(res => {
        console.log(res)
        if (res.data.code == 0 && res.data.total && res.data.total != 0) {
          res.data.rows.forEach((v, i) => {
            v.sortNo = i + 1
            this.supportList.push(v)
          });
          setData('supportData', this.supportList)
          this.loading = false
        }
      })
    }
  }
};
</script>