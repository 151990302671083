<template>
  <div>
    <section class="ad a1H a1I[120px] a3A a1k a3g[3%]">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L a1M a3s[100px] wow fadeInUp"
              style="max-width: 700px"
              data-wow-delay=".1s">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                我们的团队
              </h2>
              <p class="a1S aH md:a1T a1U md:a1U"
                style="margin-top:50px">
                {{ teamList.introduction }}
              </p>
            </div>
          </div>
        </div>
        <div class="a8 a1K ab[-16px]">
          <div class="a7 md:a1_/2 lg:a1_/3 ae"
            v-for="(item,index) in teamList.department"
            :key="index">
            <div class="a33 aw dark:a3w[#1D2144] a13 a2Y lg:a37 xl:a1l a1V wow fadeInUp"
              data-wow-delay=".1s">
              <div class="a8 a9 a2v">

              </div>
              <p class="aH a1S dark:aI a1U a3V a2B a2z dark:a2o a2M dark:a2M a23"
                style="height:90px">
                {{item.slogan }}
              </p>
              <div class="a8 a9">
                <div class="a1w a2p aB[50px] a7 at[50px] a2G">
                  <img :src="item.image"
                    alt="image" />
                </div>
                <div class="a7">
                  <h5 class="a1T lg:aH xl:a1T a1h dark:aI a2P a2K">
                    {{item.name}}
                  </h5>
                  <p class="a1b a1S">{{item.english_name}}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="a3 a3W a_ a1Y[-1]">
        <img src="static/picture/CR.png/"
          alt="">
      </div>
      <div class="a3 a5 a3X a1Y[-1]">
        <img src="static/picture/CL.png/"
          alt="">
      </div>
      <iframe width="100%" height="450" frameborder="0" name="waseePanorama" scrolling="no" src="https://859kjzlgo.wasee.com/wt/859kjzlgo?def_sid=2017693"></iframe>
    </section>
  </div>
</template>
<script>
import { getDepartment } from '../../api/api'
export default {
  data() {
    return {
      teamList: []
    }
  },
  created() {
    this.getTeamList()
  },
  methods: {
    getTeamList() {
      getDepartment().then(res => {
        this.teamList = res.data.rows[0]
      })
    }
  }
}
</script>