<template>
  <div>
    <footer class="ad a1H a1k a1Z wow fadeInUp footer"
      data-wow-delay=".1s">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 md:a1_/2 lg:a20/12 xl:a1D/12 ae">
            <div class="aB[360px] footer_">
              <a href="index"
                class="a22 a23">
                <img :src="footerList.logo"
                  alt="logo" />
              </a>
              <p class="a1S aH a1R a46 a1N">
                {{footerList.slogan}}
              </p>
              <div class="a8 a9">
                <div class=" aH a1R a22 a1S a1Q">
                  <i class="el-icon-phone"></i>
                  <a href="javascript:void(0)"
                    class=" hover:a1W">
                    {{footerList.tel_number}}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="a7 sm:a1_/2 md:a1_/2 lg:aU/12 xl:aU/12 ae">
            <div class="footer_">
              <h2 class="a1g a1A dark:aI a27 a1V">产品链接</h2>
              <ul>
                <li v-for="(item,index) in footerList.links"
                  :key="index">
                  <a :href="item.links_url"
                    class="aH a1R a22 a1S a1Q hover:a1W">
                    {{item.links_name}}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="a7 sm:a1_/2 md:a1_/2 lg:aU/12 xl:aU/12 ae">
            <div class="footer_">
              <h2 class="a1g a1A dark:aI a27 a1V">联系我们</h2>
              <ul v-if="footerList.contact">
                <li>
                  <div class=" aH a1R a22 a1S a1Q"
                    style="display: flex;align-items: center">
                    <svg t="1672370175445"
                      class="icon"
                      style="display: inline-block;"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="3068"
                      width="16"
                      height="16">
                      <path d="M943.0016 579.072a1373.696 1373.696 0 0 0-30.8224-82.9952l-30.5152-72.4992 0.0512-8.6528C881.7664 166.7072 733.184 0 512 0S142.2336 166.7072 142.2336 414.8736l0.0512 8.6016-29.9008 71.0656a1355.776 1355.776 0 0 0-31.488 84.6336c-36.5568 111.9232-39.3216 193.3824-8.0896 242.0224 16.896 26.3168 43.3664 43.8272 73.216 48.7424a118.6816 118.6816 0 0 0 13.1072 92.0576c35.4304 56.9856 102.5536 61.2352 200.9088 61.2352 56.7808 0 117.1968-2.9184 151.9104-5.9392 34.7136 3.072 95.0784 5.9392 151.9104 5.9392 98.3552 0 165.4784-4.2496 200.8576-61.1328 15.9232-25.6 21.8112-59.4944 13.1584-92.16a107.776 107.776 0 0 0 73.216-48.7424c31.1808-48.64 28.4672-130.0992-8.0896-242.1248z m-81.7152 189.7984l-0.768 0.0512c-19.8656 0-73.9328-83.6608-73.9328-83.6608 0 49.664-26.88 121.344-84.992 168.1408 21.76 6.4 48.4352 16.1792 65.536 28.2624 15.4112 10.8032 13.4656 21.8112 10.7008 26.3168-5.9904 9.6256-56.9856 12.8-113.92 12.8-58.4192 0-123.136-3.328-151.9104-6.4-28.7744 3.1232-93.4912 6.4-151.9104 6.4-56.9344 0-107.8784-3.1744-113.92-12.8-2.7648-4.4544-4.7104-15.5136 10.7008-26.3168 17.1008-12.032 43.776-21.8624 65.536-28.2624-58.112-46.848-84.992-118.4768-84.992-168.192 0 0-54.1184 83.6608-73.9328 83.6608l-0.768-0.0512c-8.96-1.024-20.6848-47.0016 15.5648-158.0544 7.5776-23.296 17.9712-50.432 28.4672-76.544l38.3488-91.136c0-1.0752-0.512-19.0464-0.512-28.2624C244.6336 259.0208 321.9456 102.4 512 102.4c190.0544 0 267.3664 156.6208 267.3664 312.4736 0 9.2672-0.4608 27.1872-0.512 28.2624l38.4 91.136c10.496 26.112 20.8896 53.248 28.4672 76.544 36.2496 111.0528 24.5248 157.0304 15.5648 158.0544z"
                        fill="#959cb1"
                        p-id="3069"></path>
                    </svg>
                    <a href="javascript:void(0)"
                      class=" hover:a1W"
                      style="margin-left: 5px">
                      {{ footerList.contact[0].contact_qq }}
                    </a>
                  </div>
                </li>
                <li>
                  <div class=" aH a1R a22 a1S a1Q">
                    <i class="el-icon-message"></i>
                    <a href="javascript:void(0)"
                      class=" hover:a1W">
                      {{ footerList.contact[0].contact_email }}
                    </a>
                  </div>
                </li>
                <li>
                  <div class=" aH a1R a22 a1S a1Q">
                    <i class="el-icon-location"></i>
                    <a href="javascript:void(0)"
                      class=" hover:a1W">
                      {{ footerList.contact[0].contact_address }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="a7 md:a1_/2 lg:a20/12 xl:a28/12 ae">
            <div class="footer_">
              <h2 class="a1g a1A dark:aI a27 a1V">微信</h2>
              <ul>
                <li>
                  <img :src="footerList.wechat"
                    alt="">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="ai a1k a29"
        style="padding-top: 2rem;padding-bottom: 2rem">
        <div class="aa" v-for="(item,index) in footerList.filingInfo" :key="item._index">
          <p class="a1S dark:aI aH a1M">
            <!-- {{item.copyRightInfo}} {{item.realmName}} 备案号：{{item.filingNo}} -->
           <a href="https://beian.miit.gov.cn/" target="_blank"> {{item.copyRightInfo}} {{item.realmName}} 备案号：{{item.filingNo}}</a>
          </p>
        </div>
      </div>
      <div class="a3 a_ a2a a1Y[-1]">
        <img src="static/picture/FR.png"
          alt="">
      </div>
      <div class="a3 a5 a2b a1Y[-1]">
        <img src="static/picture/FL.png"
          alt="">
      </div>
    </footer>
  </div>
</template>
<script>
import { getFooterInfo } from '../api/api'
import { setData } from '../utils/setFile'
export default {
  data() {
    return {
      footerList: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async init(){
      const localLogoData = await getData('logo')
        if(localLogoData!==null){
          this.footerList = localNewsData
        }else{
            this.getList()
        }
    },
    getList() {
      getFooterInfo().then(res => {
        this.footerList = res.data.rows[0]
        setData('logo', this.footerList.logo)
      })
    }
  }
}
</script>