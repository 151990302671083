<template>
  <div>
    <section id="products"
      class="bg-primary a1Z">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <!-- a3s[100px] -->
            <div class="a1L aB[570px] a1M mb wow fadeInUp"
              data-wow-delay=".1s">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                我们的成果
              </h2>
              <p class="a1S aH md:a1T a1U md:a1U">
                以科技创新技术前沿为核心，以诚信可靠坚持不懈为根本，为客户攻克企业难点。
              </p>
            </div>
          </div>
        </div>
        <div class="a8 a1K ab[-16px] a1x">
          <div class="a7 md:aU/3 lg:a1_/2 xl:a1_/3 ae pro_"
            v-for="(item, index) in productsList"
            :key="item._id">
            <div class="ad aw dark:av a33 a13 a2p wow fadeInUp"
              data-wow-delay=".1s">
              <div class="imgShow"
                style="height: 200px"><a target="_blank"
                  :href="productsList[index].products_url"
                  class="a7 ah ad imgShow">
                  <span class="a3 a34 a35 a1k a1w aM a9 a1x aK ae a2P a1b aI">
                    查看
                  </span>
                  <img :src="productsList[index].products_img"
                    alt="image"
                    class="a7" />
                </a></div>
              <div class="a36 sm:a2Y md:ai md:az lg:a2Y xl:ai xl:a37 2xl:a2Y">
                <h3>
                  <a :href="productsList[index].products_url"
                    class="a1g a1A dark:aI a27 sm:a2u ah a1Q hover:a1W dark:hover:a1W">
                    {{ item.products_name }}
                  </a>
                </h3>
                <a :href="productsList[index].products_url"
                  class="aH a1S a1R a38 a2E a2B a2M dark:a2o dark:a2M pro_ad">
                  {{ item.products_info }}
                </a>
                <!-- <div class="a8 a9">
                  <div
                    class="a8 a9 a39 a2O xl:a3a 2xl:a39 xl:a2A 2xl:a2O a3b a2z a2M dark:a2o dark:a2M"
                  >
                    <div class="aB[40px] a7 at[40px] a1w a2p a2G">
                      <img
                        src="static/picture/author-011.png"
                        alt="author"
                        class="a7"
                      />
                    </div>
                    <div class="a7">
                      <h4 class="a1b a1R a1h dark:aI a2K">
                        By
                        <a
                          href="javascript:void(0)"
                          class="a1h dark:aI hover:a1W dark:hover:a1W"
                        >
                          Samuyl Joshi
                        </a>
                      </h4>
                      <p class="a3c a1S">Graphic Designer</p>
                    </div>
                  </div>
                  <div class="a22">
                    <h4 class="a1b a1R a1h dark:aI a2K">Date</h4>
                    <p class="a3c a1S">15 Dec, 2023</p>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="more a1r"
          @click="goPage">更多</div>
      </div>
    </section>
  </div>
</template>
<script>
import { getProducts } from '../api/api'
import { setData, getData } from '@/utils/setFile.js'
export default {
  data() {
    return {
      productsList: [],
    };
  },
  created() {
    // let otherArr = JSON.parse(getData('products_img'))
    // if (otherArr instanceof Array) {
    //   otherArr.forEach((v, i) => {
    //     this.productsList.push({ products_img: otherArr[i] })
    //   });
    // }
    this.getProductsList()
  },
  methods: {
    getProductsList() {
      getProducts().then(res => {
        if (res.data.code == 0) {
          this.productsList = res.data.rows.slice(0, 3)
          // let productsImgArr = []
          // this.productsList.forEach((v, i) => {
          //   productsImgArr.push(this.productsList[i].products_img)
          // });
          // setData('products_img', JSON.stringify(productsImgArr))
        }
      })
    },
    goPage() {
      this.$router.push({ name: 'productsInfo' })
    }
  }
};
</script>
<style>
.more {
  display: flex;
  flex-direction: row-reverse;
  font-size: 14px;
  color: #7f7f7f;
}
.pro_ {
  padding: 20px;
  transition: all ease 0.2s;
  border-radius: 10px;
}
.pro_:hover {
  transform: translateY(-2px);
  background-color: rgba(246, 248, 255, 0.8);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
}
.pro_ad {
  height: 50px;
  margin-top: 20px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
  overflow: hidden;
  /* font-size: 15px; */
  margin-bottom: 20px;
}
</style>