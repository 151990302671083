<template>
  <div>
    <section class="ad a1H a1I[120px] a3A a1k a3g[3%]"
      id="joinUs">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L aB[570px] a1M wow fadeInUp"
              data-wow-delay=".1s"
              style="margin-bottom: 40px">
              <!-- a3s[100px] -->
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                加入我们
              </h2>
              <!-- <p class="a1S aH md:a1T a1U md:a1U">

              </p> -->
            </div>
          </div>
        </div>
        <div class="a8 a1K ab[-16px] load">
          <el-skeleton :loading="loading"
            animated>
            <template slot="template">
              <div v-for="(item,index) in 2"
                :key="index">
                <div style="margin-right:30px">
                  <el-skeleton-item style="width: 408px; height: 400px;" />
                  <div style="padding: 14px;">
                    <el-skeleton-item variant="h3"
                      style="width: 50%;" />
                    <div style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;">
                      <el-skeleton-item variant="text"
                        style="margin-right: 16px;" />
                      <el-skeleton-item variant="text"
                        style="width: 30%;" />
                    </div>
                    <div style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;">
                      <el-skeleton-item variant="text"
                        style="margin-right: 16px;" />
                    </div>
                    <div style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;">
                      <el-skeleton-item variant="text"
                        style="margin-right: 16px;" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template>
              <div class="a7 md:a1_/2 lg:a1_/3 ae"
                v-for="item in recruitList"
                :key="item.id">
                <div class="a33 aw dark:a3w[#1D2144] a13 a2Y lg:a37 xl:a1l a1V wow fadeInUp join"
                  data-wow-delay=".1s">
                  <h3>{{item.posts}}</h3>
                  <div>
                    <p class="aH a1S dark:aI a1U a3V a2B a2z dark:a2o a2M dark:a2M a23"
                      v-html="item.skill">

                    </p>
                  </div>
                  <div class="a8 a9">
                    <div class="a7">
                      <h5 class="a1T lg:aH xl:a1T a1h dark:aI a2P a2K">
                        联系人：{{item.contact}}
                      </h5>
                      <p class="a1b a1S">邮箱：{{item.email}}</p>
                      <p class="a1b a1S">电话：{{item.phone}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </el-skeleton>

        </div>
      </div>
      <div class="a3 a3W a_ a1Y[-1]">
        <svg width="238"
          height="531"
          viewbox="0 0 238 531"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect opacity="0.3"
            x="422.819"
            y="-70.8145"
            width="196"
            height="541.607"
            rx="2"
            transform="rotate(51.2997 422.819 -70.8145)"
            fill="url(#paint0_linear_83:2)"></rect>
          <rect opacity="0.3"
            x="426.568"
            y="144.886"
            width="59.7544"
            height="541.607"
            rx="2"
            transform="rotate(51.2997 426.568 144.886)"
            fill="url(#paint1_linear_83:2)"></rect>
          <defs>
            <lineargradient id="paint0_linear_83:2"
              x1="517.152"
              y1="-251.373"
              x2="517.152"
              y2="459.865"
              gradientunits="userSpaceOnUse">
              <stop stop-color="#4A6CF7"></stop>
              <stop offset="1"
                stop-color="#4A6CF7"
                stop-opacity="0"></stop>
            </lineargradient>
            <lineargradient id="paint1_linear_83:2"
              x1="455.327"
              y1="-35.673"
              x2="455.327"
              y2="675.565"
              gradientunits="userSpaceOnUse">
              <stop stop-color="#4A6CF7"></stop>
              <stop offset="1"
                stop-color="#4A6CF7"
                stop-opacity="0"></stop>
            </lineargradient>
          </defs>
        </svg>
      </div>
      <div class="a3 a5 a3X a1Y[-1]">
        <svg width="279"
          height="106"
          viewbox="0 0 279 106"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M-57 12L50.0728 74.8548C55.5501 79.0219 70.8513
                            85.7589 88.2373 79.3692C109.97 71.3821 116.861
                            60.9642 156.615 63.7423C178.778 65.291 195.31
                            69.2985 205.911 62.3533C216.513 55.408 224.994
                            47.7682 243.016 49.1572C255.835 50.1453 265.278
                            50.8936 278 45.3373"
              stroke="url(#paint0_linear_72:302)"></path>
            <path d="M-57 1L50.0728 63.8548C55.5501 68.0219 70.8513
                            74.7589 88.2373 68.3692C109.97 60.3821 116.861
                            49.9642 156.615 52.7423C178.778 54.291 195.31
                            58.2985 205.911 51.3533C216.513 44.408 224.994
                            36.7682 243.016 38.1572C255.835 39.1453 265.278
                            39.8936 278 34.3373"
              stroke="url(#paint1_linear_72:302)"></path>
            <path d="M-57 23L50.0728 85.8548C55.5501 90.0219 70.8513
                            96.7589 88.2373 90.3692C109.97 82.3821 116.861
                            71.9642 156.615 74.7423C178.778 76.291 195.31
                            80.2985 205.911 73.3533C216.513 66.408 224.994
                            58.7682 243.016 60.1572C255.835 61.1453 265.278
                            61.8936 278 56.3373"
              stroke="url(#paint2_linear_72:302)"></path>
            <path d="M-57 35L50.0728 97.8548C55.5501 102.022 70.8513
                            108.759 88.2373 102.369C109.97 94.3821 116.861
                            83.9642 156.615 86.7423C178.778 88.291 195.31
                            92.2985 205.911 85.3533C216.513 78.408 224.994
                            70.7682 243.016 72.1572C255.835 73.1453 265.278
                            73.8936 278 68.3373"
              stroke="url(#paint3_linear_72:302)"></path>
          </g>
          <defs>
            <lineargradient id="paint0_linear_72:302"
              x1="256.267"
              y1="53.6717"
              x2="-40.8688"
              y2="8.15715"
              gradientunits="userSpaceOnUse">
              <stop stop-color="#4A6CF7"
                stop-opacity="0"></stop>
              <stop offset="1"
                stop-color="#4A6CF7"></stop>
            </lineargradient>
            <lineargradient id="paint1_linear_72:302"
              x1="256.267"
              y1="42.6717"
              x2="-40.8688"
              y2="-2.84285"
              gradientunits="userSpaceOnUse">
              <stop stop-color="#4A6CF7"
                stop-opacity="0"></stop>
              <stop offset="1"
                stop-color="#4A6CF7"></stop>
            </lineargradient>
            <lineargradient id="paint2_linear_72:302"
              x1="256.267"
              y1="64.6717"
              x2="-40.8688"
              y2="19.1572"
              gradientunits="userSpaceOnUse">
              <stop stop-color="#4A6CF7"
                stop-opacity="0"></stop>
              <stop offset="1"
                stop-color="#4A6CF7"></stop>
            </lineargradient>
            <lineargradient id="paint3_linear_72:302"
              x1="256.267"
              y1="76.6717"
              x2="-40.8688"
              y2="31.1572"
              gradientunits="userSpaceOnUse">
              <stop stop-color="#4A6CF7"
                stop-opacity="0"></stop>
              <stop offset="1"
                stop-color="#4A6CF7"></stop>
            </lineargradient>
          </defs>
        </svg>
      </div>
    </section>
  </div>
</template>
<script>
import { getRecruitInfo } from '../api/api'
export default {
  data() {
    return {
      loading: true,
      recruitList: []
    }
  },
  created() {
    this.getRecruit()
  },
  methods: {
    getRecruit() {
      getRecruitInfo().then(res => {
        this.recruitList = res.data.rows
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.join {
  transition: all ease 0.2s;
}
.join:hover {
  transform: translateY(-2px);
  background-color: rgba(246, 248, 255, 0.8);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
}

@media (min-width: 540px) {
  .load > div {
    display: flex;
  }
}
::v-deep .el-skeleton {
  display: flex !important;
}
</style>