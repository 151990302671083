<template>
  <div style="width: 100%">
    <el-skeleton style="width: 100%"
      :loading="loading"
      animated>
      <template slot="template">
        <section class="ad a1H aq[120px]">
          <div class="aa">
            <loding></loding>
          </div>
        </section>
      </template>
      <template>
        <div>
          <section class="ad a1H aq[120px]">
            <div class="aa">
              <div class="a8 a1K ab[-16px]">
                <div class="a7 ae">
                  <div class="a1L aB[570px] a1M a3I wow fadeInUp"
                    data-wow-delay=".1s">
                    <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                      WHO WE ARE
                    </h2>
                    <p class="a1S aH md:a1T a1U md:a1U">
                      {{ companyList.slogan }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="a8 a1K ab[-16px]">
                <div class="a7 ae">
                  <div class="a1L  a13 a2p wow fadeInUp"
                    data-wow-delay=".15s">
                    <div class="ad a9 a1x info_box">
                      <img :src="companyList.show_img"
                        alt="company image"
                        class="a7 a2Q a2R a2S"
                        style="width:400px" />
                      <div class="info_boxRight">
                        <div class="img_box">
                          <img v-for="(item, index) in companyList.show_icon"
                            :key="index"
                            :src="item"
                            alt="">
                        </div>
                        <div class="a1S aH md:a1T a1U md:a1U"
                          style="margin-top: 20px;font-size: 16px;">
                          <p>{{ companyList.profile }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="a3 a1X a5 a_ a1Y[-1]">
              <img src="static/picture/shape.svg"
                alt="shape"
                class="a7" />
            </div>
          </section>

          <devHistory></devHistory>
          <mainBusiness></mainBusiness>
          <serviceAdvantage></serviceAdvantage>
          <!-- <firmTeam></firmTeam> -->
        </div>
      </template>
    </el-skeleton>
  </div>

</template>
<script>
import devHistory from './devHistory.vue'
import mainBusiness from './mainBusiness.vue'
import serviceAdvantage from '../about/serviceAdvantage.vue'
import firmTeam from './firmTeam.vue'
import loding from '../loading/loading.vue'
import { getCompanyInfo } from '../../api/api'
import { setData, getData } from '@/utils/setFile.js'
export default {
  components: {
    devHistory,
    serviceAdvantage,
    mainBusiness,
    firmTeam,
    loding
  },
  data() {
    return {
      loading: true,
      companyList: [],
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      getCompanyInfo().then(res => {
        this.companyList = res.data.rows[0]
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
/* 小于540px */
@media (max-width: 540px) {
  .info_box {
    flex-direction: column;
  }

  .img_box {
    display: none;
  }

  .info_boxRight {
    padding: 20px;
  }
}

/* 小于960 大于 540px */
@media (max-width: 960px) and (min-width: 540px) {
}

/* 大于1140px */
@media (min-width: 1140px) {
}

/* 大于1320px */
@media (min-width: 1320px) {
  .info_box {
    flex-direction: row;
  }

  .img_box {
    display: flex;
  }

  .info_boxRight {
    width: 700px;
    margin-left: 35px;
    background-color: #fff;
  }
}

.info_box {
  display: flex;
  justify-content: flex-start;
  /* background-color: #aab6ff; */
}

.img_box img {
  margin-right: 50px;
}

.img_box img:first-child {
  margin-left: 50px;
}

.info_boxRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 700px; */
  /* margin-left: 35px; */
  /* background-color: #fff; */
}

.info_boxRight p {
  line-height: 2.7;
  color: rgba(149, 156, 177, var(--tw-text-opacity));
}

.dev_box {
  display: flex;
  flex-direction: column;
}

.dev_line {
  border: 3px solid #a5b6fb;
  border-radius: 5px;
}

.dev_item_top {
  height: 300px;
  display: flex;
}

.dev_item_top_left {
  margin-left: 350px;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}

.dev_item_top_left div:first-child {
  width: 2px;
  height: 150px;
  border: 2px solid #b4c1f3;
}

.dev_item_top_right {
  display: flex;
  flex-direction: column-reverse;
  margin-left: 400px;
  align-items: center;
}

.dev_item_top_right div:first-child {
  width: 2px;
  height: 200px;
  border: 2px solid #b4c1f3;
}

.dev_item_buttom {
  height: 400px;
  display: flex;
  /* justify-content: space-around; */
}

.dev_item_buttom_left {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  align-items: center;
  width: 200px;
}

.dev_item_buttom_right {
  display: flex;
  flex-direction: column;
  margin-left: 400px;
  align-items: center;
  width: 200px;
}

.dev_item_buttom_left div:first-child {
  width: 2px;
  height: 200px;
  border: 2px solid #b4c1f3;
}

.dev_item_buttom_right div:first-child {
  width: 2px;
  height: 150px;
  border: 2px solid #b4c1f3;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #b4c1f3;
}

.h2ti {
  font-size: 30px;
  font-weight: bold;
}
</style>