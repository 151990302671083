<template>
  <div id="app">
    <pageHeader></pageHeader>
    <router-view></router-view>
    <pageFooter></pageFooter>
  </div>
</template>

<script>
import '../public/static/css/tailwind.css'
import '../public/static/css/css2.css'
import '../public/static/css/glightbox.min.css'
import '../public/static/css/animate.css'
import pageHeader from './components/header.vue'
import pageFooter from './components/footer.vue'
export default {
  components: {
    pageHeader,
    pageFooter,
    // indexNewsVue,
    // indexA,
    // indexProducts
  },
}
</script>

<style>
</style>
