<template>
  <div>
    <section class="ad a1H a1I[120px] a3A">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L aB[655px] a1M a3s[100px] wow fadeInUp"
              data-wow-delay=".1s">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                主要业务
              </h2>
              <p class="a1S aH md:a1T a1U md:a1U aB[570px] a1L"
                style="margin-top:50px">
                服务宗旨：持续为客户创造最优质的服务与价值
                诚实守信、精益求精、追求卓越。
                深度探讨客户需求与痛点，高效高质服务，实现双赢。
              </p>
            </div>
          </div>
        </div>
        <div class="a8 a1K ab[-16px]">
          <div class="a7 md:a1_/2  ae"
            v-for="(item,index) in businessList"
            :key="index">
            <div class="ad a1H aw dark:a3w[#1D2144] a1n a1l a42 a13 a1V wow fadeInUp"
              data-wow-delay=".1s">
              <div class="a8 ac a9">
                <h3 class="a1g a1A dark:aI a1O a2X price">
                  <span class="amount">{{item.name}}</span>
                </h3>
                <h4 class="aI a1g a27 a2X">Lite</h4>
              </div>
              <div>
                <div class="a8 a9 a30"
                  v-for="(item,index) in item.examp"
                  :key="index">
                  <span class="a1k a29 a1W aB[18px] a7 at[18px] a2A a8 a9 a1x a1w">
                    <svg width="8"
                      height="6"
                      viewbox="0 0 8 6"
                      class="a26">
                      <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z">
                      </path>
                    </svg>
                  </span>
                  <p class="aH a1R a1S a45">{{ item.business_exap}}</p>
                </div>

              </div>
              <div class="a3 a1X a_ a1Y[-1]">
                <img src="static/picture/SR.png"
                  alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="a3 a5 a1X a1Y[-1]">
        <img src="static/picture/text.png"
          alt="">
      </div>
    </section>
  </div>
</template>
<script>
import { getBusinessList } from '../../api/api'
export default {
  data() {
    return {
      businessList: []
    }
  },
  created() {
    this.getBusinessCate()
  },
  methods: {
    getBusinessCate() {
      getBusinessList().then(res => {
        res.data.rows.forEach((v, i) => {
          this.businessList.push({ name: res.data.rows[i].business_name, examp: res.data.rows[i].businessList })
        });
      })
    }
  }
}
</script>
<style scoped>
</style>