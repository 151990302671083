<template>
  <div>
    <el-skeleton style="width: 100%"
      :loading="loading"
      animated>
      <template slot="template">
        <section class="ad a1H aq[120px]">
          <div class="aa">
            <loding></loding>
          </div>
        </section>
      </template>
      <template>
        <section id="news"
          class="a1k a3g[3%] a1J[50px]"
          style="padding-top:100px">
          <div class="aa news_box">
            <div class="a8 a1K ab[-16px]">
              <div class="a7 ae">
                <div class="a1L  a1M wow fadeInUp"
                  data-wow-delay=".1s"
                  style="margin-bottom:20px">
                  <p class="news_title">{{info.news_title}}</p>
                  <!-- <span style="font-size: 14px">日期：{{info._add_time_str.slice(0,10)}}</span> -->
                </div>
                <div style="border: 1px dashed #e5e5e5;"></div>
              </div>
            </div>
          </div>

          <div class="aa">
            <div class="a8 a1K ab[-16px]">
              <div class="a7 ae">
                <div class="a1L a1M wow fadeInUp content_box"
                  style="padding-top:20px;"
                  data-wow-delay=".1s">
                  <!-- <el-result :subTitle="info.news_text">
                <template slot="icon">
                  <img :src="info.news_img"
                    alt="">
                </template>
              </el-result> -->
                  <div class="content"
                    v-html="info.news_text">

                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </template>

    </el-skeleton>

  </div>
</template>
<script>
import loding from '../loading/loading.vue'
import { getNewsInfo } from '../../api/api'
export default {
  components: {
    loding
  },
  data() {
    return {
      id: '',
      loading: true,
      info: []
    }
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.popstate);
    }
  },
  destroyed() { // 销毁vm组件
    // 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener('popstate', this.popstate);
  },
  created() {
    const _id = this.$route.query.id
    this.id = _id
    let params = { id: this.id }
    if (this.id) {
      this.getData(params)
    }
  },
  methods: {
    getData(params) {
      getNewsInfo(params).then(res => {
        console.log(res)
        this.info = res.data.rows[0]
        this.loading = false
      })
    },
    popstate(e) {
      console.log(e, 'eeeeeeeeeeeeeeee')
      this.$router.back()
    }
  },
}
</script>
<style scoped>
.news_title {
  font-size: 24px;
  padding-top: 30px;
}
.news_img {
  height: 200px;
  padding-top: 20px;
  width: 500px;
}
::v-deep .el-result {
  text-align: left;
}
::v-deep .el-result__subtitle p {
  text-indent: 2em;
  font-size: 18px;
}
@media (min-width: 1320px) {
  ::v-deep .el-result__subtitle {
    width: 1200px;
    padding-top: 30px;
  }
}
::v-deep .el-result__subtitle {
  /* width: 1200px; */
  padding-top: 30px;
}

::v-deep .content p {
  text-indent: 2em;
  font-size: 16px;
  line-height: 1.8;
  text-align: left;
  margin-top: 20px;
  display: inline-block;
}
</style>