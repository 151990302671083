<template>
  <div>
    <section id="products"
      class="bg-primary a1Z a1I[120px] a3A"
      style="background-color: rgba(74, 108, 247, var(--tw-bg-opacity))">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L aB[570px] a1M wow fadeInUp"
              data-wow-delay=".1s"
              style="margin-bottom:50px">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                我们的成果
              </h2>
              <p class="a1S aH md:a1T a1U md:a1U">

                诚实守信、精益求精、追求卓越。<br />

                深度探讨客户需求与痛点，高效高质服务，实现双赢。

              </p>
            </div>
          </div>
        </div>
        <div class="a8 a1K ab[-16px] a1x">
          <div class="a7 md:aU/3 lg:a1_/2 xl:a1_/3 ae"
            style="width: 1320px;">

            <el-skeleton style="width: 100%"
              :loading="loading"
              animated>
              <template slot="template">
                <div class="wrap">
                  <div class="box"
                    v-for="(item, index) in 8">
                    <div class="box-top">
                      <el-skeleton-item variant="image"
                        style="width: 240px; height: 240px;" />
                      <div class="title-flex">
                        <el-skeleton-item variant="h3"
                          style="margin-right: 16px;" />
                        <el-skeleton-item variant="text"
                          style="width: 30%; margin-top: 16px;" />
                      </div>
                      <el-skeleton-item class="description"
                        variant="h3"
                        style="margin-right: 16px;" />
                    </div>
                  </div>
                </div>
              </template>
              <template>
                <div class="wrap">
                  <div class="box"
                    v-for="(item, index) in productsList">
                    <div class="box-top  a1r"
                      @click="checkInfo(item.products_url)">
                      <img class="box-image"
                        :src="item.products_img"
                        alt="Girl Eating Pizza">
                      <div class="title-flex">
                        <h3 class="box-title"
                          style="font-size: 22px;">{{ item.products_name }}</h3>
                        <p class="user-follow-info">{{ item.products_info }}</p>
                      </div>
                      <p class="description">{{ item.products_content }}</p>
                      <!-- :title="item.products_content" -->
                    </div>
                    <!-- <a class="button"
                      @click="view(item.products_content)">查看详情</a> -->
                    <el-popover placement="bottom"
                      width="400"
                      trigger="click">
                      <el-card class="box-card">
                        <div class="text item">
                          {{ item.products_content}}
                        </div>
                      </el-card>
                      <a class="button"
                        slot="reference">查看详情</a>
                    </el-popover>
                    <!-- :href="item.products_url"
                      target="_blank" -->
                  </div>
                </div>
              </template>
            </el-skeleton>

          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import { getProducts } from '../../api/api'
import { setData, getData } from '@/utils/setFile.js'
import './css/style.css'
export default {
  data() {
    return {
      loading: true,
      productsList: [],
    };
  },
  created() {

    // let otherArr = JSON.parse(getData('products_img'))
    // if (otherArr.length != 0) {
    //   otherArr.forEach((v, i) => {
    //     this.productsList.push({ products_img: otherArr[i] })
    //   });
    // }
    this.init()
  },
  mounted() {

  },
  methods: {
    getProductsList() {
      getProducts().then(res => {
        if (res.data.code == 0) {
          this.productsList = res.data.rows
          // let productsImgArr = []
          // this.productsList.forEach((v, i) => {
          //   productsImgArr.push(this.productsList[i].products_img)
          // });
          setData('productsData', this.productsList)
          this.loading = false
        }
      })
    },
    async init() {
      const localData = await getData('productsData')
      if (localData !== null) {
        this.productsList = localData
        this.loading = false
      } else {
        this.getProductsList()
      }

    },
    checkInfo(url) {
      window.open(`${url}`)
    },
    view(val) {
      console.log(val)
    }
  }
};
</script>
<style>
.viewDialog {
}
</style>