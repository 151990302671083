import Vue from 'vue'
// import 'element-ui/lib/theme-chalk/index.css'
import { Dialog, Result, Pagination, Table, Collapse, CollapseItem, Message, Icon, Carousel, CarouselItem, Skeleton, SkeletonItem, Popover, Card, MessageBox } from 'element-ui'

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dialog)
Vue.use(Result)
Vue.use(Pagination)
Vue.use(Table)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Icon)
Vue.use(Skeleton)
Vue.use(SkeletonItem)
Vue.use(Popover)
Vue.use(Card)
Vue.component(MessageBox.name, MessageBox)
Vue.component(Message.name, Message)
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.config.productionTip = false