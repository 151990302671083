<template>
  <div>

    <section id="contact"
      class="a1I[120px] a3A">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 lg:a2q/12 ae">
            <div class="a1k a3g[3%] dark:av a13 a3t a2s lg:a2v sm:a3B[55px] lg:a3t xl:a3B[55px] wow fadeInUp"
              data-wow-delay=".15s
              ">
              <h2 class="a1g a1A dark:aI a2u sm:a1O lg:a2u xl:a1O a30">
                联系我们
              </h2>
              <p class="a1S aH a1R a2s">
                感谢对我们的关注，四川华企数信时刻用心对待我们的每一位客户！
              </p>
              <form>
                <div class="a8 a1K ab[-16px]">
                  <div class="a7 md:a1_/2 ae">
                    <div class="a23">
                      <label for="name"
                        class="ah a1b a1R a1h dark:aI a30">
                        姓名
                      </label>
                      <input type="text"
                        v-model="info.contact_name"
                        placeholder="Enter your name"
                        class="a7 a3l a3m dark:a3w[#242B51] a13 a33 dark:a1n a1i az a1S aH a3x a3o focus-visible:aE focus:a3p" />
                    </div>
                  </div>
                  <div class="a7 md:a1_/2 ae">
                    <div class="a23">
                      <label for="email"
                        class="ah a1b a1R a1h dark:aI a30">
                        邮箱
                      </label>
                      <input type="email"
                        v-model="info.contact_email"
                        placeholder="Enter your email"
                        class="a7 a3l a3m dark:a3w[#242B51] a13 a33 dark:a1n a1i az a1S aH a3x a3o focus-visible:aE focus:a3p" />
                    </div>
                  </div>
                  <div class="a7 ae">
                    <div class="a23">
                      <label for="message"
                        class="ah a1b a1R a1h dark:aI a30">
                        您的留言
                      </label>
                      <textarea name="message"
                        v-model="info.contact_message"
                        rows="5"
                        placeholder="Enter your Message"
                        class="a7 a3l a3m dark:a3w[#242B51] a13 a33 dark:a1n a1i az a1S aH a3x a3o focus-visible:aE focus:a3p a3C"></textarea>
                    </div>
                  </div>
                  <div class="a7 ae">
                    <a @click="submit"
                      class="aH a1R aI a1k a3q a1m hover:a2i hover:a1n a13 a1p a1a a2j">
                      提交
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="a7 lg:a20/12 ae">
            <div class="ad a1H a13 a1k a3g[3%] dark:a29 a2Y sm:a3t lg:a2Y xl:a3t a2v wow fadeInUp"
              data-wow-delay=".2s
              ">
              <!-- <img src="static/picture/map.png"
                alt=""> -->
              <div style="height:500px;"
                id="container"></div>
              <div class="a3 a4 a5 a1Y[-1]">
                <svg width="370"
                  height="596"
                  viewbox="0 0 370 596"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <mask id="mask0_88:141"
                    style="mask-type: alpha"
                    maskunits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="370"
                    height="596">
                    <rect width="370"
                      height="596"
                      rx="2"
                      fill="#1D2144"></rect>
                  </mask>
                  <g mask="url(#mask0_88:141)">
                    <path opacity="0.15"
                      d="M15.4076 50.9571L54.1541 99.0711L71.4489 35.1605L15.4076 50.9571Z"
                      fill="url(#paint0_linear_88:141)"></path>
                    <path opacity="0.15"
                      d="M20.7137 501.422L44.6431 474.241L6 470.624L20.7137 501.422Z"
                      fill="url(#paint1_linear_88:141)"></path>
                    <path opacity="0.1"
                      d="M331.676 198.309C344.398 204.636 359.168 194.704 358.107 180.536C357.12 167.363 342.941 159.531 331.265 165.71C318.077 172.69 318.317 191.664 331.676 198.309Z"
                      fill="url(#paint2_linear_88:141)"></path>
                    <g opacity="0.3">
                      <path d="M209 89.9999C216 77.3332 235.7 50.7999 258.5 45.9999C287 39.9999 303 41.9999 314 30.4999C325 18.9999 334 -3.50014 357 -3.50014C380 -3.50014 395 4.99986 408.5 -8.50014C422 -22.0001 418.5 -46.0001 452 -37.5001C478.8 -30.7001 515.167 -45 530 -53"
                        stroke="url(#paint3_linear_88:141)"></path>
                      <path d="M251 64.9999C258 52.3332 277.7 25.7999 300.5 20.9999C329 14.9999 345 16.9999 356 5.49986C367 -6.00014 376 -28.5001 399 -28.5001C422 -28.5001 437 -20.0001 450.5 -33.5001C464 -47.0001 460.5 -71.0001 494 -62.5001C520.8 -55.7001 557.167 -70 572 -78"
                        stroke="url(#paint4_linear_88:141)"></path>
                      <path d="M212 73.9999C219 61.3332 238.7 34.7999 261.5 29.9999C290 23.9999 306 25.9999 317 14.4999C328 2.99986 337 -19.5001 360 -19.5001C383 -19.5001 398 -11.0001 411.5 -24.5001C425 -38.0001 421.5 -62.0001 455 -53.5001C481.8 -46.7001 518.167 -61 533 -69"
                        stroke="url(#paint5_linear_88:141)"></path>
                      <path d="M249 40.9999C256 28.3332 275.7 1.79986 298.5 -3.00014C327 -9.00014 343 -7.00014 354 -18.5001C365 -30.0001 374 -52.5001 397 -52.5001C420 -52.5001 435 -44.0001 448.5 -57.5001C462 -71.0001 458.5 -95.0001 492 -86.5001C518.8 -79.7001 555.167 -94 570 -102"
                        stroke="url(#paint6_linear_88:141)"></path>
                    </g>
                  </g>
                  <defs>
                    <lineargradient id="paint0_linear_88:141"
                      x1="13.4497"
                      y1="63.5059"
                      x2="81.144"
                      y2="41.5072"
                      gradientunits="userSpaceOnUse">
                      <stop stop-color="white"></stop>
                      <stop offset="1"
                        stop-color="white"
                        stop-opacity="0"></stop>
                    </lineargradient>
                    <lineargradient id="paint1_linear_88:141"
                      x1="28.1579"
                      y1="501.301"
                      x2="8.69936"
                      y2="464.391"
                      gradientunits="userSpaceOnUse">
                      <stop stop-color="white"></stop>
                      <stop offset="1"
                        stop-color="white"
                        stop-opacity="0"></stop>
                    </lineargradient>
                    <lineargradient id="paint2_linear_88:141"
                      x1="338"
                      y1="167"
                      x2="349.488"
                      y2="200.004"
                      gradientunits="userSpaceOnUse">
                      <stop stop-color="white"></stop>
                      <stop offset="1"
                        stop-color="white"
                        stop-opacity="0"></stop>
                    </lineargradient>
                    <lineargradient id="paint3_linear_88:141"
                      x1="369.5"
                      y1="-53"
                      x2="369.5"
                      y2="89.9999"
                      gradientunits="userSpaceOnUse">
                      <stop stop-color="white"></stop>
                      <stop offset="1"
                        stop-color="white"
                        stop-opacity="0"></stop>
                    </lineargradient>
                    <lineargradient id="paint4_linear_88:141"
                      x1="411.5"
                      y1="-78"
                      x2="411.5"
                      y2="64.9999"
                      gradientunits="userSpaceOnUse">
                      <stop stop-color="white"></stop>
                      <stop offset="1"
                        stop-color="white"
                        stop-opacity="0"></stop>
                    </lineargradient>
                    <lineargradient id="paint5_linear_88:141"
                      x1="372.5"
                      y1="-69"
                      x2="372.5"
                      y2="73.9999"
                      gradientunits="userSpaceOnUse">
                      <stop stop-color="white"></stop>
                      <stop offset="1"
                        stop-color="white"
                        stop-opacity="0"></stop>
                    </lineargradient>
                    <lineargradient id="paint6_linear_88:141"
                      x1="409.5"
                      y1="-102"
                      x2="409.5"
                      y2="40.9999"
                      gradientunits="userSpaceOnUse">
                      <stop stop-color="white"></stop>
                      <stop offset="1"
                        stop-color="white"
                        stop-opacity="0"></stop>
                    </lineargradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import { addContact, getMapKey } from '../api/api'
import { Debounce } from '../utils/setFile'
export default {
  data() {
    return {
      info: {
        contact_name: '',
        contact_email: '',
        contact_message: ''
      },
      key: '',
      Lat: 0,
      Lng: 0,
      address: ''
    }
  },
  async mounted() {
    await getMapKey().then(res => {
      this.key = res.data.info.mapKey
      this.Lat = res.data.info.latitude
      this.Lng = res.data.info.longitude
      this.address = res.data.info.address
    })
    const mapScript = document.createElement('script')
    mapScript.type = 'text/javascript'
    mapScript.src = `https://map.qq.com/api/gljs?v=1.exp&key=${this.key}`
    document.body.appendChild(mapScript)
    mapScript.onload = () => {
      //加载完成后初始化地图
      this.initMap()
    }
  },
  methods: {
    submit() {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
      if (this.info.contact_name != '' && this.info.contact_email != '' && this.info.contact_message != '') {
        if (regEmail.test(this.info.contact_email)) {
          addContact(this.info)
          // this.$message({
          //   message: '提交成功！请等待联系',
          //   type: 'success'
          // });
          this.$alert('提交成功！请等待联系', '', {
            confirmButtonText: '确定',
            center: true,
          });
          this.info.contact_name = ''
          this.info.contact_email = ''
          this.info.contact_message = ''
        } else {
          // this.$message.error('请输入合法的邮箱');
          this.$alert('请输入合法的邮箱', '', {
            confirmButtonText: '确定',
            center: true,
          });
        }
      } else {
        // this.$message.error('请输入相关信息');
        this.$alert('请输入相关信息', '', {
          confirmButtonText: '确定',
          center: true,
        });
      }
    },
    initMap() {
      //定义地图中心点坐标
      var center = new TMap.LatLng(this.Lat, this.Lng)
      //定义map变量，调用 TMap.Map() 构造函数创建地图
      var map = new TMap.Map('container', {
        center: center,//设置地图中心点坐标
        pitch: 65,
        zoom: 17,  //设置地图缩放级别
      });


      //创建并初始化标注
      var markerLayer = new TMap.MultiMarker({
        map: map,  //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new TMap.MarkerStyle({
            "width": 25,  // 点标记样式宽度（像素）
            "height": 35, // 点标记样式高度（像素）
            "background-color": 'red',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": { x: 16, y: 32 }
          })
        },
        //点标记数据数组
        geometries: [{
          "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId": 'myStyle',  //指定样式id
          "position": new TMap.LatLng(this.Lat, this.Lng),  //点标记坐标位置
          "properties": {//自定义属性
            "title": "marker1"
          }
        }]
      });

      // 创建文本
      var label = new TMap.MultiLabel({
        id: 'label-layer',
        map: map, //设置折线图层显示到哪个地图实例中
        //文字标记样式
        styles: {
          'label': new TMap.LabelStyle({
            'color': '#66b1ff', //颜色属性
            'size': 20, //文字大小属性
            'offset': { x: 15, y: 30 }, //文字偏移属性单位为像素
            'angle': 0, //文字旋转属性
            'alignment': 'center', //文字水平对齐属性
            'verticalAlignment': 'middle' //文字垂直对齐属性
          })
        },
        //文字标记数据
        geometries: [{
          'id': 'label_1', //点图形数据的标志信息
          'styleId': 'label', //样式id
          'position': center, //标注点位置
          'content': this.address, //标注文本
        }]
      });
    }
  }
}
</script>
<style>
.el-message-box__message p {
  font-size: 18px;
}
.el-button--primary {
  background-color: rgb(74, 108, 247) !important;
  border-color: rgb(74, 108, 247) !important;
}

.el-message-box__btns button:hover {
  border-color: #6d88f9 !important;
  background-color: #6d88f9 !important;
}
.el-button--small {
  padding: 12px 20px !important;
}
.el-message-box__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 540px) {
  .el-message-box {
    width: 420px !important;
    border-radius: 16px !important;
  }
}

@media (max-width: 540px) {
  .el-message-box {
    width: 300px !important;
    border-radius: 16px !important;
  }
}
</style>