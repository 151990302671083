<template>
  <div>
    <section class="a1I[100px] a1J[120px]">
      <div class="aa">
        <div class="a8 a1K a9 ab[-16px]">
          <div class="a7 lg:a1_/2 ae">
            <div class="a1M lg:a2I a2s lg:a2t wow fadeInUp"
              data-wow-delay=".15s">
              <img :src="meritsList.merits_img"
                alt="about image"
                class="ag a1L lg:a2J"
                style="width: 450px" />
            </div>
          </div>
          <div class="a7 lg:a1_/2 ae">
            <div class="aB[470px] wow fadeInUp"
              data-wow-delay=".2s">
              <div class="a1N">
                <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] lg:a1P xl:a24[45px] a2D sm:a2D md:a2D lg:a2D xl:a2D a2E">
                  服务优势
                </h2>
                <p class="a1R a1S aH sm:a1T a1U sm:a1U a2F">
                  {{meritsList.introduction}}
                </p>
              </div>
              <div class="a1N"
                v-for="(item,index) in meritsList.merits"
                :key="index">
                <h3 class="a1g a1A dark:aI a27 sm:a2u lg:a27 xl:a2u a1Q">
                  {{item.merits_name}}
                </h3>
                <p class="a1S aH sm:a1T a1U sm:a1U a1R">
                  {{ item.merits_detail }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getMerits } from '../../api/api'
export default {
  data() {
    return {
      meritsList: []
    }
  },
  created() {
    this.getMeritsList()
  },
  methods: {
    getMeritsList() {
      getMerits().then(res => {
        this.meritsList = res.data.rows[0]
      })
    }
  }
}
</script>