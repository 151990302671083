<template>
  <div>
    <section class="a1k a3g[3%] a1I[120px] a1J[50px] newsListBox">
      <div class="aa">
        <div class="a1K ab[-16px] out_box">
          <el-skeleton :loading="loading"
            animated>
            <template slot="template">
              <div class="newsDir">
                <el-skeleton-item variant="h3"
                  style="width: 220px;" />
                <div style="margin-top: 16px; height: 16px;">
                  <el-skeleton-item variant="text"
                    style="margin-right: 16px;" />
                  <el-skeleton-item variant="text"
                    style="width: 50%;" />
                </div>
              </div>
            </template>
            <template>
              <div class="newsDir">
                <div class="dirTitle"><span>资讯目录</span><span>News</span></div>
                <el-collapse v-model="activeName"
                  accordion>
                  <el-collapse-item :title="item.name"
                    v-for="(item,index) in newsCategory"
                    :key="index"
                    :name="index">
                    <div v-for="(item,i) in item.newsList.slice(0, 3)"
                      :key="i"><span @click="goToNews(item)"
                        class="a1r"> {{ item.news_title }}<i class="el-icon-view"
                          style="margin-left:10px"></i><span>{{ item.news_clicks }}</span></span>

                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </template>
          </el-skeleton>

          <el-skeleton :loading="loading"
            animated>
            <template slot="template">
              <div class="newSection">
                <div class="newsBox a1r"
                  v-for="(item,index) in 2"
                  :key="index">
                  <el-skeleton-item variant="image"
                    style="width:250px;height:150px" />
                  <div style="width:600px;margin-left: 20px;">
                    <el-skeleton-item variant="text"
                      class="newsBox_right_up" />
                    <el-skeleton-item variant="text"
                      class="newsBox_right_down" />
                  </div>
                </div>
              </div>
            </template>
            <template>
              <div class="newSection">
                <div class="newsBox a1r"
                  v-for="(item,index) in newsList"
                  :key="index"
                  @click="goToNews(item)">
                  <div class="newsBox_left imgShow"><img :src="item.news_img"
                      alt=""></div>
                  <div class="newsBox_right">
                    <div class="newsBox_right_up">{{item.news_title}}</div>
                    <div class="newsBox_right_c"></div>
                    <div class="newsBox_right_down"
                      v-html="item.news_text"></div>
                    <div style="display: flex;justify-content: space-between"><span style="font-size:14px"
                        class="a1r">更多>></span>
                      <span style="font-size:14px"
                        class="a1r">发布时间：{{ item._add_time_str.slice(0,10) }}</span>
                    </div>
                  </div>
                </div>
                <div style="margin-top:50px">
                  <el-pagination background
                    @current-change="handleCurrentChange"
                    layout="prev, pager, next"
                    :total="total"
                    :page-size="pageInfo.pageSize">
                  </el-pagination>
                </div>
              </div>
            </template>
          </el-skeleton>

        </div>
      </div>
    </section>
  </div>
</template>
<script>

import { getNews, getNewsCate, addClicks } from '../../api/api'
import { setData, getData } from '@/utils/setFile.js'
export default {

  data() {
    return {
      loading: true,
      newsList: [],
      currentPage: 1,
      total: 0,
      pageInfo: {
        pageIndex: 1,
        pageSize: 5,
      },
      activeName: '0',
      newsCategory: []
    }
  },
  created() {
    // let otherArr = JSON.parse(getData('news_img'))
    // if (otherArr instanceof Array) {
    //   otherArr.forEach((v, i) => {
    //     this.newsList.push({ news_img: otherArr[i] })
    //   });
    // }
    this.init()
  },
  methods: {
    async init() {
      const localNewsData = await getData('newsListData')
      if (localNewsData !== null) {
        this.newsList = localNewsData
        this.loading = false
      } else {
        this.getNewsList()
      }
      const localCatesData = await getData('newsCategory')
      if (localCatesData !== null) {
        this.newsCategory = localCatesData
      } else {
        this.getCate()
      }

    },
    getNewsList() {
      getNews(this.pageInfo).then(res => {
        if (res.data.code == 0 && res.data.total && res.data.total != 0) {
          this.newsList = res.data.rows
          setData('newsListData', this.newsList)
          this.loading = false
        }
        // this.newsList = res.data.rows
        // let newsImgArr = []
        // this.newsList.forEach((v, i) => {
        //   newsImgArr.push(this.newsList[i].news_img)
        // });

      })
    },
    getCate() {
      getNewsCate().then(res => {
        res.data.rows.forEach((v, i) => {
          if (res.data.code == 0 && res.data.total && res.data.total != 0) {
            this.newsCategory.push({ name: res.data.rows[i].cate_name, newsList: res.data.rows[i].newsList })
            setData('newsCategory', this.newsCategory)
          }
          // this.newsCategory.push({ name: res.data.rows[i].cate_name, newsList: res.data.rows[i].newsList })
        });
      })
    },
    goToNews(val) {
      console.log(val._id)
      this.$router.push({
        name: "newsInfo",
        query: { id: val._id }
      })
      let data = { _id: val._id }
      addClicks(data)
    },
    handleCurrentChange(newPage) {
      this.pageInfo.pageIndex = newPage
      this.getNewsList()
    },
  }
}
</script>
<style>
.el-collapse-item__content div {
  border-bottom: 1px solid rgb(233, 230, 230);
  /* margin-bottom: 10px; */
  margin: 15px 15px 15px 15px;
}
.el-collapse-item__content div:last-child {
  border-bottom: 0;
}

::v-deep .el-collapse-item__header {
  margin-left: 5px;
  background-color: rgba(246, 248, 255);
}
.el-collapse-item {
  background-color: rgba(246, 248, 255);
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0px;
}
</style>