<template>
  <div class="main-box main-box-pos">
    <section id="products"
      class="bg-primary a1Z a1I[120px] a3A main-box-pos">
      <div class="aa">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="a1L aB[570px] a1M wow fadeInUp"
              style="margin-bottom:50px"
              data-wow-delay=".1s">
              <h2 class="a1A dark:aI a1g a1O sm:a1P md:a24[45px] a1Q">
                我们做了什么
              </h2>
              <p class="a1S aH md:a1T a1U md:a1U">
                华企数信致力于为客户解决相关企业难题
              </p>
            </div>
          </div>
        </div>
        <el-skeleton :loading="loading"
          animated>
          <template slot="template">
            <div class="a8 a1K ab[-16px] a1x sh540">
              <!-- <el-skeleton-item variant="image" style="width: 80%; height: 400px" /> -->
              <loadingCom></loadingCom>
            </div>
          </template>
          <template>
            <div class="a8 a1K ab[-16px] a1x sh540"
              style="margin:20px 10px;"
              v-for="(item, index) in solutionList"
              :key="index">
              <div class="test_">
                <div class="test"
                  :style="{ left: item.sortNo % 2 == 1 && w ? '300px' : '' }">
                  <div class="test_box"
                    :class="{ 'test_box_': item.sortNo % 2 == 1 && w }">
                    <div>
                      <span style="margin-right:10px"><i :class="item.icon_text"
                          style="font-size:24px;vertical-align:baseline"></i></span>
                      <span>{{ item.title }}</span>
                    </div>
                    <div>{{ item.content }}</div>
                    <div>
                      <p class="ttt">查看案例</p>
                    </div>
                  </div>

                  <div class="left"
                    v-if="item.sortNo % 2 == 1">
                    <div class="left_img">
                      <img :src="item.img_url"
                        alt="">
                    </div>

                    <div class="left_cricle">
                      <div class="left_cricle_box"
                        @click="showVideo(item.introduction_video)">
                        <div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right"
                    v-if="item.sortNo % 2 == 0">
                    <div class="right_img">
                      <img :src="item.img_url"
                        alt="">
                    </div>
                    <div class="right_cricle">
                      <div class="right_cricle_box"
                        @click="showVideo(item.introduction_video)">
                        <div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-skeleton>

      </div>
      <div v-if="isShow == 1"
        class="alert"
        @click="isShow = 0">
        <div class="xuanze">
          <video controls
            autoplay
            class="video-box">
            <source :src="video_url" />
          </video>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getSolutionList } from '../../api/api'
import { setData, getData } from '@/utils/setFile.js'
import loadingCom from '../loading/loading.vue'
export default {
  components: {
    loadingCom
  },
  data() {
    return {
      loading: true,
      solutionList: [],
      isShow: 0,
      video_url: '',
      w: '',
    };
  },
  created() {
    // let otherArr = JSON.parse(getData('products_img'))
    // if (otherArr instanceof Array) {
    //     otherArr.forEach((v, i) => {
    //         this.productsList.push({ products_img: otherArr[i] })
    //     });
    // }
    // this.getSolutiontData()
    this.init()
  },
  mounted() {
    var w = document.documentElement.clientWidth;
    var h = document.documentElement.clientHeight;
    if (w > 1140) {
      this.w = true
    } else {
      this.w = false
    }
  },
  methods: {
    getSolutiontData() {
      getSolutionList().then(res => {
        if (res.data.code == 0 && res.data.total && res.data.total != 0) {
          res.data.rows.forEach((v, i) => {
            v.sortNo = i + 1
            this.solutionList.push(v)
          });
          this.loading = false
          setData('solutionData', this.solutionList)
        }
      })
    },
    showVideo(video_url) {
      if (video_url !== '') {
        this.video_url = video_url
        this.isShow = 1
      }

    },
    async init() {
      const localData = await getData('solutionData')
      if (localData !== null) {
        this.solutionList = localData
        this.loading = false
      } else {
        this.getSolutiontData()
      }
    },
  }
};
</script>
<style>
/* 小于540px */
@media (max-width: 540px) {
  .sh540 {
    height: 600px;
  }

  .test_ {
    width: 100%;
  }

  .test_box {
    margin-left: 0px;
    width: 100%;
    padding: 15px;
  }

  .test {
    width: 100%;
    left: 0px;
    height: 350px;
  }

  .right {
    width: 100%;
    height: 100%;
    top: 50%;
    transform: translateY(-35%);
    margin-top: 20px;
  }

  .test_box {
    margin-left: 0px;
    width: 100%;
    padding: 15px;
  }

  .right_cricle {
    width: 100px;
    height: 100px;
    top: -50%;
    left: 35%;
  }

  .right_cricle_box {
    width: 80px;
    height: 80px;
  }

  .left {
    width: 100%;
    height: 100%;
    /* top: 15%; */
    top: 10%;
    margin-top: 20px;
  }

  .left_cricle {
    width: 100px;
    height: 100px;
    top: -50%;
    left: 35%;
  }

  .left_cricle_box {
    width: 80px;
    height: 80px;
  }
}

/* 小于960 大于 540px */
@media (max-width: 960px) and (min-width: 540px) {
  .sh540 {
    height: 850px;
  }

  .test_ {
    width: 720px;
  }

  .test {
    width: 670px;
    height: 400px;
  }

  .test_box {
    margin-left: 70px;
    width: 450px;
  }

  .right {
    width: 100%;
    height: 100%;
    margin-top: 26px;
    top: 50%;
    transform: translateY(-35%);
    margin-top: 60px;
  }

  .right_img {
    width: 100%;
    height: 100%;
  }

  .right_cricle {
    width: 130px;
    height: 130px;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .right_cricle_box {
    width: 100px;
    height: 100px;
  }

  .left {
    width: 100%;
    height: 100%;
    top: 15%;
    margin-top: 60px;
  }

  .left_img {
    width: 100%;
    height: 100%;
  }

  .left_cricle {
    width: 130px;
    height: 130px;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .left_cricle_box {
    width: 100px;
    height: 100px;
  }
}

/* 大于1140px */
@media (min-width: 1140px) {
  .test_ {
    width: 1140px;
  }

  .test {
    width: 800px;
    height: 400px;
    left: 100px;
  }

  .test_box {
    margin-left: 100px;
    width: 450px;
  }

  .right {
    /* top: -50%;
    left: 20%; */
    width: 430px;
    height: 280px;
    top: -25%;
    left: 25%;
    transform: translateY(-50%);
  }

  .right_img {
    width: 100%;
    height: 100%;
  }

  .right_cricle {
    width: 130px;
    height: 130px;
    left: 30%;
    transform: translateY(-130%);
  }

  .right_cricle_box {
    width: 100px;
    height: 100px;
  }

  .left {
    /* top: -55%;
    left: -15%; */
    width: 430px;
    height: 280px;
    top: -60%;
    left: -25%;
  }

  .left_img {
    width: 100%;
    height: 100%;
  }

  .left_cricle {
    width: 130px;
    height: 130px;
    left: 35%;
    transform: translateY(-130%);
  }

  .left_cricle_box {
    width: 100px;
    height: 100px;
  }
}

/* 大于1320px */
@media (min-width: 1320px) {
  .test_ {
    width: 1320px;
  }

  .test {
    width: 900px;
    left: 110px;
    height: 400px;
  }

  .test_box {
    margin-left: 100px;
    width: 450px;
  }

  .right {
    width: 430px;
    height: 280px;
    top: -25%;
    left: 25%;
    transform: translateY(-50%);
  }

  .right_img {
    width: 100%;
    height: 100%;
  }

  .right_cricle {
    width: 130px;
    height: 130px;
    top: -50%;
    left: -70px;
    transform: translateY(-50%);
  }

  .right_cricle_box {
    width: 100px;
    height: 100px;
  }

  .left {
    width: 430px;
    height: 280px;
    top: -60%;
    left: -25%;
  }

  .left_img {
    width: 100%;
    height: 100%;
  }

  .left_cricle {
    width: 130px;
    height: 130px;
    top: -50%;
    left: 85%;
    transform: translateY(-50%);
  }

  .left_cricle_box {
    width: 100px;
    height: 100px;
  }
}

.test_ {
  height: 400px;
  margin-bottom: 30px;
}

.test {
  background: linear-gradient(45deg, #80b3ff 20%, #a8acfa 50%, #7b80d4 80%);
  border-radius: 20px;
  position: relative;
}

.test_box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 300px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

.test_box div:last-child {
  width: 135px;
  height: 60px;
  border: 3px solid #fff;
  border-radius: 0px 30px 30px;
}

.ttt {
  color: rgb(255, 255, 255);
  font-weight: bold;
  display: inline-block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.right {
  float: right;
  position: relative;
  border-radius: 5px;
}

.right_img {
  overflow: hidden;
  border-radius: 20px;
}

.right_cricle {
  box-sizing: border-box;
  float: left;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(45deg, #80b3ff 20%, #a8acfa 50%, #7b80d4 80%);
}

.right_cricle_box {
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.right_cricle_box div {
  width: 0;
  height: 0;
  border: 20px solid;
  border-color: transparent transparent transparent #4a6cf7;
  position: relative;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
}

.left {
  float: left;
  position: relative;
}

.left_img {
  overflow: hidden;
  border-radius: 20px;
}

.left_cricle {
  box-sizing: border-box;
  float: left;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(45deg, #80b3ff 20%, #a8acfa 50%, #7b80d4 80%);
}

.left_cricle_box {
  border-radius: 50%;
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left_cricle_box div {
  width: 0;
  height: 0;
  border: 20px solid;
  border-color: transparent transparent transparent #4a6cf7;
  position: relative;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
}

.alert {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.xuanze {
  /* width: 50%;
  height: 50%; */
  width: 600px;
  /* height: 600px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 520px) {
  .xuanze {
    width: 300px;
  }
}
.video-box {
  margin: 0 auto;
}
.test_box_ {
  left: 30%;
  flex-direction: column;
}
</style>