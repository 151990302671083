<template>
  <div>
    <section class="a3K">
      <div class="aa"
        style="margin-bottom: 30px;">
        <div class="a8 a1K ab[-16px]">
          <div class="a7 ae">
            <div class="av dark:a1k dark:a1Z a13 a8 a1K a9 a1x ai a1l sm:a3L md:aq[40px] md:a3M[50px] xl:a3B[50px] 2xl:aq[60px] 2xl:a3M[70px] wow fadeInUp"
              data-wow-delay=".1s"
              style="background-color: rgba(74, 108, 247, var(--tw-bg-opacity));--tw-bg-opacity: 3%;overflow: hidden;">
              <div style="margin-top: 20px;">

                <!-- <p class="wave" v-for="(item,index) in imgList">
                  <img :src="item.url" alt="">
                  <span>{{item.des}}</span>
                </p> -->
                <el-carousel :interval="4000"
                  type="card"
                  height="26rem"
                  style="margin: 0px auto; overflow-x: unset;">
                  <el-carousel-item v-for="item in imgList"
                    :key="item.sortNo">
                    <img :src="item.url"
                      alt="">
                  </el-carousel-item>
                </el-carousel>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

export default {

  data() {
    return {
      imgList: [
        { url: require('../../../public/static/picture/certificate/cer1.jpg'), des: "《保障综合系统平台》", sortNo: 0 },
        { url: require('../../../public/static/picture/certificate/cer2.jpg'), des: "《称重系统》", sortNo: 1 },
        { url: require('../../../public/static/picture/certificate/cer3.jpg'), des: "《费用报销系统》", sortNo: 2 },
        { url: require('../../../public/static/picture/certificate/cer4.jpg'), des: "《合同管理系统》", sortNo: 3 },
        { url: require('../../../public/static/picture/certificate/cer5.jpg'), des: "《竞争情报系统》", sortNo: 4 },
        { url: require('../../../public/static/picture/certificate/cer6.jpg'), des: "《智慧家居定制1》", sortNo: 5 },
        { url: require('../../../public/static/picture/certificate/cer7.jpg'), des: "《智慧家居定制2》", sortNo: 6 },
        { url: require('../../../public/static/picture/certificate/cer8.jpg'), des: "《BI报表》", sortNo: 7 },
        { url: require('../../../public/static/picture/certificate/cer9.jpg'), des: "《CRM》", sortNo: 8 },
        { url: require('../../../public/static/picture/certificate/cer10.jpg'), des: "《CRM系统平台》", sortNo: 9 },
        { url: require('../../../public/static/picture/certificate/cer11.jpg'), des: "《FFOMS电商平台》", sortNo: 10 },
        { url: require('../../../public/static/picture/certificate/cer12.jpg'), des: "《OA系统》", sortNo: 11 },
        { url: require('../../../public/static/picture/certificate/cer13.jpg'), des: "《SCM》", sortNo: 12 },
        { url: require('../../../public/static/picture/certificate/cer14.jpg'), des: "《SCM系统》", sortNo: 13 },
        { url: require('../../../public/static/picture/certificate/cer15.jpg'), des: "《TSR单招投标系统》", sortNo: 14 }
      ]
    };
  },
  created() {

  },
  methods: {

  }

};
</script>
<style scoped>
.wave {
  float: left;
  margin: 14px;
  animation: wave ease-in-out 0.5s infinite alternate;
  transform-origin: center -20px;
}

.wave:hover {
  animation-play-state: paused;
}

.wave img {
  border: 5px solid #f8f8f8;
  display: block;
  width: 200px;
  height: 250px;
}

.wave span {
  display: block;
  text-align: center;
}

.wave:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1.5px solid #ffffff;
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}

.wave:before {
  content: "";
  position: absolute;
  top: -23px;
  left: 50%;
  display: block;
  height: 44px;
  width: 47px;
  /* background-image: url(https://www.jq22.com/newjs/FW6g79WBY.png); */
  background-size: 20px 20px;
  background-repeat: no-repeat;
  z-index: 16;
}

@keyframes wave {
  0% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(-3deg);
  }
}
</style>
